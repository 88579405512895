<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">发布图文</div>
        <div class="right" @click="sexAreaPub">发布</div>
      </div>
      <div class="section">
        <div class="text">
          <div class="tag" v-show="tagList.length>0"><span class="tag-name" v-for="(item,index) in tagList" :key="index">#{{item}}</span></div>
          <textarea placeholder="请输入文字描述" v-model="keyWords"></textarea>
        </div>
        <div class="rule flex">
          <router-link tag="span" to="rules">规则说明</router-link>
        </div>
        <div class="sec">请选择照片（未提交前可修改，最多九张）</div>
        <div class="add flex">
          <div class="choosed-box" v-for="(item,index) in chooseList" :key="index">
            <img class="choose-img" :src="item" alt="">
            <div class="delete" @click="deleteImg(index)"></div>
          </div>
          <div class="box" v-show="chooseList.length<9">
            <span class="add-img"></span>
            <input class="add-input" @change="imgChoose($event)" type="file" accept="image/*" multiple="multiple">
          </div>
        </div>
        <div class="ht">自定义标签</div>
        <div class="zdy">#自定义<input type="text" class="zdy-i" v-model="tag" @focus="tagsFocus" @change="changeTag" ref="taginput"></div>
        <div class="add-ht">添加热门标签</div>
        <div class="list flex">
          <div class="item" v-for="item in hotTags" :key="item.id" @click="addTags($event,item.name)">{{item.name}}</div>
        </div>
      </div>
    </div>
    <van-overlay :show="loading" class="loading flex-center">
      <van-loading type="spinner" color="#f21313" size="24px" vertical>正在努力上传中...</van-loading>
    </van-overlay>
  </div>
</template>
<script>
import {sexAreaPub,sexHotTags,sendImg} from '@/api/app.js';
export default {
  data(){
    return{
      loading:false,//图片上传中的加载动画
      tag:'',
      tagList:[],//选中的标签列表
      chooseList:[],//选中图片的
      hotTags:[],//热门标签列表
      keyWords:'',//要发表的文字
      param:[],//formdata格式上传图片去拿到一个图片链接传给后台
      imgUrl:[],//上传图片后拿到的一个图片链接列表
    }
  },
  created(){
    this.sexHotTags();
  },
  methods:{
    deleteImg(index){//删除某张照片
      this.chooseList.splice(index,1);
      this.param.splice(index,1);
    },
    async sexAreaPub(){//点击发布按钮
      if(!this.keyWords.trim()){
        this.$toast('请输入描述内容！');
      }else if(this.tagList.length == 0){
        this.$toast('至少选择1个标签哟');
      }else if(this.chooseList.length < 3){
        this.$toast('至少3张图片哟');
      }else{
        this.loading = true;
        for(let i = 0;i < this.param.length;i ++){
          this.sendImgTo(this.param[i]);
        }
      }
    },
    async sendImgTo(req){//发送图片得到一个返回url
      let ret = await this.$Api(sendImg,req);
      if(ret.code == 200){
        this.imgUrl.push(ret.data.path);
        if(this.param.length == this.imgUrl.length){//当所有图片都上传拿到结果后
          this.sendImgWords();
        }
      }else{
        this.$toast('图片上传失败，请重新上传');
        this.loading = false;
      }
    },
    async sendImgWords(){//发布图文接口
      let req = {
          content:this.keyWords,
          tags:this.tagList,
          picList:this.imgUrl,
        }
        let ret = await this.$Api(sexAreaPub,req);
        this.loading = false;
        if(ret.code == 200){
          this.$toast('发布成功');
          this.$router.go(-1);//返回到色区
        }else{
          this.$toast(ret.tip);
        }
    },
    imgChoose(event){//图片文件选择
      if((event.target.files.length + this.chooseList.length) > 9){
        this.$toast('最多只能选择9张图片，请重新选择你要发布的图片!');
        return;
      }
      let This = this;
      let files = event.target.files;
      for(let i = 0 ;i < files.length;i ++){
        let reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onloadend = function(){
          This.chooseList.push(this.result);
        }
        let param = new FormData();
        param.append('file',files[i]);
        this.param.push(param);
      }
    },
    async sexHotTags(){//获取热门标签
      let ret = await this.$Api(sexHotTags,undefined);
      if(ret.code == 200){
        let list = ret.data?ret.data:[];
        this.hotTags = list;
      }
    },
    addTags(event,name){//点击标签事件
      let dom = event.target;
      if(dom.classList.value.indexOf('active') > -1){//点击已经选择的标签就取消选中
        this.tagList.forEach((item,i) => {
          if(item == name){
            this.tagList.splice(i,1);
          }
        })
        dom.classList.remove('active');
      }else{
        if(this.tagList.length >=3){
          this.$toast('最多选择3个标签哟');
          return;
        }
        this.tagList.push(name);
        dom.classList.add('active');
      }
    },
    tagsFocus(){//自定义标签聚焦
      if(this.tagList.length >=3){
        this.$toast('最多选择3个标签哟');
        this.$refs.taginput.blur();
      }
    },
    changeTag(){
      this.$refs.taginput.blur();
      this.tag = this.tag.trim();
      this.tagList.unshift(this.tag);
      this.tag = '';
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;    -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  margin-right:24px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:46px;
  height:20px;
  border-radius: 12px;
  background:#ffffff;
  font-size:14px;
  color:$vermillion;
  text-align: center;
  line-height: 20px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.text{
  height:151px;
  border-radius: 4px;
  background:#f6f6f6;
}
.text>textarea{
  padding:6px;
  width:100%;
  height: 100%;
  outline:none;
  resize:none;
  border:none;
  background: none;
  text-indent: 6px;
  font-size:14px;
  box-sizing: border-box;
}
.rule{
  margin:10px 0 4px;
  justify-content: right;
}
.rule>span{
  padding:4px 10px;
  background:$vermillion;
  color:#ffffff;
  font-size:13px;
  border-radius: 7px;
}
.sec{
  color:#515151;
  font-size:10px;
  margin-bottom:14px;
}
.box{
  width:108px;
  height:108px;
  text-align: center;
  line-height: 96px;
  color:#ffffff;
  font-size:60px;
  border-radius: 9px;
  background:rgba(125,125,125,0.64);
  position: relative;
}
.choosed-box{
  width:108px;
  height:108px;
  border-radius: 9px;
  overflow: hidden;
  position: relative;
}
.delete{
  position:absolute;
  top:6px;
  right:4px;
  width:20px;
  height:20px;
  background:url('../../assets/png/delete.png') center center no-repeat;
  background-size:100%;
}
.choose-img{
  width:100%;
  height:100%;
}
.add-img{
  display: inline-block;
  width:28px;
  height:28px;
  background:url('../../assets/png/add.png') center center no-repeat;
  background-size:100%;
}
.add-input{
  position: absolute;
  width:100%;
  height:100%;
  left:0;
  top:0;
  opacity: 0;
}
.add{
  flex-wrap: wrap;
}
.add>div{
  margin-right:15px;
  margin-top:10px;
}
.add>div:nth-child(3n){
  margin-right: 0;
}
.ht{
  margin-top:20px;
}
.zdy{
  margin-top:10px;
  width:76px;
  height:28px;
  text-align: center;
  line-height: 28px;
  color:#ffffff;
  font-size:14px;
  border-radius:14px;
  background: $vermillion;
  position: relative;
}
.add-ht{
  margin-top:10px;
  font-size:14px;
  margin-bottom:3px;
}
.list{
  flex-wrap: wrap;
}
.item{
  margin-top:12px;
  padding:8px 14px;
  border-radius: 17px;
  border:1px solid #474747;
  margin-right:10px;
  color:#515151;
}
.tag{
  padding:6px 6px 0;
}
.tag>span{
  font-size:15px;
  color:$vermillion;
  font-weight: bold;
}
.zdy-i{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity: 0;
}
.tag-name{
  margin-right:6px;
}
.tag-name:last-child{
  margin-right: 0;
}
.active{
  background:$vermillion;
  color:#fff;
  border:1px solid $vermillion;
}
.loading{
  align-items: center;
}
</style>
