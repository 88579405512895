import { render, staticRenderFns } from "./sendImg.vue?vue&type=template&id=47dfb5b5&scoped=true&"
import script from "./sendImg.vue?vue&type=script&lang=js&"
export * from "./sendImg.vue?vue&type=script&lang=js&"
import style0 from "./sendImg.vue?vue&type=style&index=0&id=47dfb5b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47dfb5b5",
  null
  
)

export default component.exports